import React from 'react';
import styled from 'styled-components';

interface PlansSwitchRadioProps {
    country: string;
    onChangeValue: Function;
}

const PlanSwitchRadio: React.FC<PlansSwitchRadioProps> = (props: PlansSwitchRadioProps) => {
    return (
        <SwitcherContainer>
            <SwitchButton onClick={() => { props.onChangeValue("Canada"); }}>
                <SwitchImage src="/images/canada.png" alt="" />
                <input checked={props.country == "Canada"} type="radio" value="Canada" name="country" onChange={() => { props.onChangeValue("Canada"); }} />
            </SwitchButton>
            {/*<SwitchButton onClick={() => { props.onChangeValue("USA"); }}>*/}
            {/*    <SwitchImage src="/images/us.png" alt="" />*/}
            {/*    <input checked={props.country == "USA"} type="radio" value="USA" name="country" onChange={() => { props.onChangeValue("USA"); }} />*/}
            {/*</SwitchButton>*/}
        </SwitcherContainer>
    );
}

const SwitcherContainer = styled.div`
    display: flex;
    gap: 30px;
`;

const SwitchButton = styled.div`
    cursor: pointer;
    user-select: none;
    width: 151px;
    height: 107px;
    background: #fafafa 0% 0% no-repeat padding-box;
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px 0;
    border: 1px solid #DDDDDD;
    background-color: #fafafa;
`;

const SwitchImage = styled.img`
    width: 50px;
    height: 50px;
`;


export default PlanSwitchRadio;